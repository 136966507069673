<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 13:56:43
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:58:45
 * @FilePath: \crm-education\src\views\student\my_admission_test\index.vue
-->
<template>
  <div class="admission-test">
    <a-card class="cus-card noborder mb-20" :bordered="false" v-for="item in list" :key="item.id">
      <div class="d-flex col justify-center align-center">
        <p class="required my-20">
          该测试仅用于测试您的相关知识掌握程度，不计算学分，不影响入学，请您认真按要求参与完成即可。
        </p>
        <div class="admission-test-img-wrap">
          <img src="@/assets/img/admission-test.png" alt="" />
        </div>
        <p class="py-20 fs-18 fw-bold">{{ item.subjectName }}</p>
        <div class="py-20">
          <a-button
            class="cus-button"
            type="primary"
            target="_blank"
            :href="
              `${linkBasrUrl}/studentStudy/getEnterschoolUserTest?forId=${item.forId}&&subjectId=${item.subjectId}`
            "
            v-if="item.status == 2"
            >参与测试</a-button
          >
          <p class="color-red fs-18" v-else>已经参与</p>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'student_admission_test',
  data() {
    return {
      list: [],
      linkBasrUrl: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      let res = await api.student.getEnterSchoolUser()
      this.list = res.data
      this.linkBasrUrl = process.env.VUE_APP_TEST_LINK_BASE_URL
    }
  }
}
</script>

<style lang="scss" scoped>
.admission-test-img-wrap {
  width: 400px;
}
</style>
